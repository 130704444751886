.gpt4__footer {
    display: flex;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}

.gpt4__footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.gpt4__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 54px;
    line-height: 72px;
    text-align: center;
    letter-spacing: -0.04em;
}

.gpt4__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    text-align: center;
    margin-bottom: 10rem;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
}

.gpt4__footer-btn:hover {
    background-color: #fff;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
.gpt4__footer-btn:hover p{
    color: var(--color-footer);
}


.gpt4__footer-btn p {
    font-family: var(--font-family);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    word-spacing: 2px;
}

.gpt4__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
}

.gpt4__footer-links div {
    width: 250px;
    margin: 1rem;
}

.gpt4__footer-links__logo {
    display: flex;
    flex-direction: column;
}

.gpt4__footer-links__logo img {
    width: 118px;
    height: 30px;
    margin-bottom: 1rem;
}
.gpt4__footer-links__logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
}

.gpt4__footer-links__div {
    display: flex;
    flex-direction: column;
}

.gpt4__footer-links__div h4 {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 1rem;
}
.gpt4__footer-links__div p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    margin: 0.5rem 0;
    cursor: pointer;
}

.gpt4__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}
.gpt4__footer-copyright p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
}

@media screen and (max-width: 850px) {
    .gpt4__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
    

}
@media screen and (max-width: 550px) {
    .gpt4__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }
    .gpt4__footer-links__div {
        margin: 1rem 0;
    }
    .gpt4__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}