.gpt4__possibility {
    display: flex;
    flex-direction: row;
}

.gpt4__possibility-image {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 2rem;
}

.gpt4__possibility-image img {
    width: 100%;
    height: auto;
}
.gpt4__possibility-image p {
    width: 100%;
    font-family: var(--font-family);
    font-size: 10px;
    color: #a0a0a0;
    text-align: left;
}

.gpt4__possibility-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.gpt4__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
    margin-bottom: 2rem;
}

.gpt4__possibility-content h1 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin-bottom: 2rem;
}

.gpt4__possibility-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #81AFDD;
    margin-bottom: 2rem;
}

.gpt4__possibility-content h4:last-child {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #FF8A71;
}

@media screen and (max-width: 950px) {
    .gpt4__possibility {
        flex-direction: column;
    }
    .gpt4__possibility-image {
        margin: 1rem;
    }
    .gpt4__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 650px) {
    .gpt4__possibility-content h1 {
        font-size: 28px;
        line-height: 40px;
    }
    .gpt4__possibility-content p {
        font-size: 14px;
        line-height: 25px;
    }
    .gpt4__possibility-content h4 {
        font-size: 14px;
        line-height: 25px;
    }
}