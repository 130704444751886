.gpt4__header {
    display: flex;
    flex-direction: row;
}

.gpt4__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 4rem;
}

.gpt4__header-content h1 {
    font-family:var(--font-family);
    font-style: normal;
    font-weight: 800;
    font-size: 54px;
    line-height: 72px;
    letter-spacing: -0.04em;
} 
.gpt4__header-content p {
    font-family:var(--font-family);
    color:var(--color-text);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin-top: 1.5rem;
}

.gpt4__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: row;
}

.gpt4__header-content__input input {
    flex: 2;
    width: 100%;
    height: 50px;
    background: var(--color-footer);
    border-radius: 5px 0px 0px 5px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #ffffff;
    -webkit-border-radius: 5px 0px 0px 5px;
    -moz-border-radius: 5px 0px 0px 5px;
    -ms-border-radius: 5px 0px 0px 5px;
    -o-border-radius: 5px 0px 0px 5px;
}

.gpt4__header-content__input button {
    flex:0.7;
    width: 100%;
    min-height: 50px;
    border-radius: 0px 5px 5px 0px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    background: #FF4820;
    color: #FFFFFF;
    cursor: pointer;
    padding: 0 1rem;
    border: none;
    -webkit-border-radius: 0px 5px 5px 0px;
    -moz-border-radius: 0px 5px 5px 0px;
    -ms-border-radius: 0px 5px 5px 0px;
    -o-border-radius: 0px 5px 5px 0px;
    transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    -moz-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
}

.gpt4__header-content__input button:hover {
    background-color: white;
    color: #FF4820;
}

.gpt4__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-top: 2rem;
}
.gpt4__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt4__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}
.gpt4__header-image {
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.gpt4__header-image img {
    width: 100%;
    height: auto;
    
}
.gpt4__header-image p {
    width: 100%;
    font-family: var(--font-family);
    font-size: 10px;
    color: #a0a0a0;
    text-align: right;
}

@media screen and (max-width: 1050px) {
    .gpt4__header {
        flex-direction: column;
    }
    .gpt4__header-content {
        margin: 0 0 3rem;
    }
}


@media screen and (max-width: 650px) {
    .gpt4__header h1 {
        font-size: 48px;
        line-height: 60px;
    }
    .gpt4__header p {
        font-size: 16px;
        line-height: 24px;
    }
    .gpt4__header-content__people {
        flex-direction: column;
    }
    .gpt4__header-content__people p {
        margin: 0;
    }

    .gpt4__header-content__input input,
    .gpt4__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 650px) {
    .gpt4__header h1 {
        font-size: 36px;
        line-height: 48px;
    }
    .gpt4__header p {
        font-size: 14px;
        line-height: 24px;
    }
    
    .gpt4__header-content__input input,
    .gpt4__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}