.gpt4__blog-container__article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.gpt4__blog-container__article-image {
    width: 100%;
    height: 100%;
    background: var(--color-footer);
    position: relative
}
.gpt4__blog-container__article-image img {
    width: 100%;
    height: 100%;
}

.gpt4__blog-container__article-image p {
    position: absolute;
    font-family: var(--font-family);
    font-size: 10px;
    bottom: 5px;
    right: 10px;
    color: #a0a0a0;
}

.gpt4__blog-container__article-content a:hover {
    text-decoration: underline;
}

.gpt4__blog-container__article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.gpt4__blog-container__article-content p {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 12px;
    line-height: 35px;
    color: #FFFFFF;
}
.gpt4__blog-container__article-content h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 5rem;
    
}
.gpt4__blog-container__article-content p:last-child {
    cursor: pointer;

}

@media screen and (max-width: 550px) {
    .gpt4__blog-container__article-content h3 {
        font-size: 18px;
        line-height: 25px;
    }
}